@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200;300;400;500;600;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Playfair+Display&family=Tiro+Bangla&display=swap');

.app {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  overflow: hidden;
  height: auto;
  color: white;
  font-weight: light;

  background: #212529;

  @keyframes blink {
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  #title {
    font-family: 'Manrope';
  }

  #overlay1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #f1f1f1;
  }

  #overlay1:hover {
    opacity: 0.95;
  }

  #text1 {
    color: rgb(24, 18, 46);
    font-size: 17px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    text-decoration: none;
  }

  #image1 {
    opacity: 0;
  }

  h5 {
    overflow: hidden;
    text-decoration: none;
  }

  .close {
    font-size: 1.5rem;
  }

  .col-12 img {
    margin: 2rem;
    width: 100%;
  }

  #logo {
    font-weight: lighter;
  }

  i {
    color: white;
  }

  #nav {
    color: purple;
  }
}
